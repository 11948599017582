<template>
  <article>
    <hgroup><h1>TV화면 캡쳐 컴포넌트 개발하기 + AWS Rekognition</h1></hgroup>
    <h2>Prerequisites</h2>
    <ul class="checklist">
      <li>Python 3.7</li>
      <li>Flask</li>
    </ul>
    <h2>Dependency</h2>
    아래 public component를 사용하는 컴포넌트 입니다.
    <ul class="checklist">
      <li><a href="https://developer.damda.lge.com/docs/webos-tv/api" target="_blank">webOS TV</a></li>
    </ul>
    <h2>예제</h2>
    <div>
      <div style="margin-top: 26px;">
        AWS Rekognition의 <a href="https://docs.aws.amazon.com/rekognition/latest/dg/celebrities.html" target="_blank">Recognizing celebrity</a>를 활용한 예제입니다.
      </div>
    </div>
    <ol>
      <li><a href="/assets/img/celebrity.zip" target="_blank"> 샘플 코드</a>를 다운로드 받으세요.</li>
      <li>
        image/celebrities_recognizer.py #11 에서 본인의 <a href="https://docs.aws.amazon.com/rekognition/latest/dg/setting-up.html" target="_blank">AWS credential</a>을 사용해주세요.
      </li>
      <li>
        컴포넌트 생성 및 배포과정을 <router-link to="/get-started/example" style="margin-left: 8px;">여기</router-link>를 참고해주세요
      </li>
      (참고) 실행 스크립트 (&lt;ZIP_FILE_NAME&gt; 수정 필요)
      <div>
        <pre><code class='hljs'>pip3 install -r {root}/&lt;ZIP_FILE_NAME&gt;/requirements.txt && python3 {root}/&lt;ZIP_FILE_NAME&gt;/app.py</code></pre>
      </div>
    </ol>
    <div>
      <div style="margin-top: 44px;">
        아래는 예제를 실행 했을 때 화면입니다. 예제에서 빨간 네모를 클릭하면 해당 유명인의 소개 링크로 이동합니다. (<a href="https://www.wikidata.org/wiki/Q20145" target="_blank">아래 예시 유명인 링크</a>)
      </div>
      <img
        src="/assets/img/example2.png"
        alt=""
        style="margin-top: 43px; margin-bottom: 100px;"

      />
    </div>
  </article>
</template>
<script>
import getStartedPage2Py from "raw-loader!@/assets/code/get-started-page2.py";
import getStartedPage2Sh from "raw-loader!@/assets/code/get-started-page2.sh";
import CodeViewer from "@/components/CodeViewer.vue";
export default {
  data() {
    return {
      getStartedPage2Py,
      getStartedPage2Sh,
    };
  },
  components: {
    CodeViewer,
  },
};
</script>
